export const isTouchable = (): any =>
    {
        const nav = navigator; // To capture navigator in current state.
    
        let maxTouchPts = 0;
        let touchEvent = false;
        if(nav.maxTouchPoints !== undefined)
        {
            maxTouchPts = Number(nav.maxTouchPoints)
        } 
            
        try 
        {
            document.createEvent('TouchEvent')
            touchEvent = true;
        } catch {}
    
        const touchStart = 'ontouchstart' in window;
        return {
            touch: {
                maxTouchPts: maxTouchPts,
                touchEvent: touchEvent,
                touchStart: touchStart
            }
        }
    }