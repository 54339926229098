import { getGeneral } from './components/general'
import { getFonts } from './components/fonts'
import { getWebglInfo, getDomBlockers } from './components/visual'
import { getAudio } from './components/audio'
import { getPlugins } from './components/plugins'
import { getMathathFingerprint } from './components/math'
import { getApplePayState } from './components/apple_pay'
import { isTouchable } from './components/touch'
import request from '../utility/request'
import { serialize } from '../utility/formHelpers'

export const fingerprint = async (): Promise<any> => {
    return await Promise.all([getGeneral(), getAudio(), getFonts(), getWebglInfo(), getDomBlockers(), getPlugins(), getMathathFingerprint(), getApplePayState(), isTouchable() ]).then(values => {
        let fingerprint: {[key: string]: string} = {}

        values.map(value => (
            fingerprint = { ...fingerprint, ...value as {} }
        ))

        let sortedFingerprint: {[key: string]: string} = {}

        sortedFingerprint = Object.keys(fingerprint)
            .sort().reduce((tempObj: {[key: string]: string}, key) => {
                tempObj[key] = fingerprint[key]
                return tempObj
            }, {})

        const data = {
            locale: window.OP_METRICS.locale,
            pathname: document.location.pathname,
            referrer: window.OP_METRICS.referrer,
            fingerprint: JSON.stringify(sortedFingerprint)
        }

        request('POST', '/6', serialize(data))
    })
}
