import { sendBeacon } from '../utility/sendBeacon'
import { LinkClickData } from '../types/interfaces'
import { isMobile } from '../utility/contextData'

// ISSUE: Safari Not Logging Clicks: https://iostructure.atlassian.net/browse/IOWEBDEV-1821

export const linkTracking = (): void => {
  const screen = isMobile()
  let type = 'touchstart'
  if (screen === 'Desktop') {
    type = 'click'
  }

  const anchors: Element[] = Array.from(document.getElementsByTagName('a'))
  anchors.forEach((el: Element) => {
    el.addEventListener(type, () => {
      const selector = el.className
      const parentSelector = el.parentElement?.className
      return logClick(selector, parentSelector)
    })
  })
}

export function logClick (
  selector: string,
  parentSelector: string | undefined
): void {
  const clickEndpoint = '/4'

  const clickData: LinkClickData = {
    elementSelector: selector,
    parentSelector: parentSelector,
    locale: window.OP_METRICS.locale,
    pathname: window.OP_METRICS.id,
    referrer: window.OP_METRICS.referrer,
    screenHeight: window.OP_METRICS.screen_height,
    screenWidth: window.OP_METRICS.screen_width,
    version: window.OP_METRICS.site_version,
    template: window.OP_METRICS.template
  }

  sendBeacon(clickEndpoint, clickData)
}
