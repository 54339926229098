import { sendBeacon } from '../utility/sendBeacon'
import { ElementImpressionData } from '../types/interfaces'

export const elementImpression = (target: HTMLElement, elementImpression: ElementImpression): void => {
  const io = new IntersectionObserver(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      Array.prototype.slice
        .call(entries)
        .forEach(function (entry: IntersectionObserverEntry) {
          if (entry.isIntersecting) {
            const impressionData: ElementImpressionData = {
              locale: window.OP_METRICS.locale,
              pathname: window.OP_METRICS.id,
              referrer: window.OP_METRICS.referrer,
              screenHeight: window.OP_METRICS.screen_height,
              screenWidth: window.OP_METRICS.screen_width,
              version: window.OP_METRICS.site_version,
              template: window.OP_METRICS.template,
              elementSelector: elementImpression.type === 'data' ? elementImpression.name + '_' + entry.target?.getAttribute('data-' + elementImpression.name) : elementImpression.name ,
              timeSeen: entry.time.toString()
            }
            observer.disconnect()

            sendBeacon('/3', impressionData)
          }
        })
    }
  )

  io.observe(target)
}
