import { isFacebook } from '../utility/contextData'
import request from '../utility/request'
import { serialize } from '../utility/formHelpers'

export const webAPICheck = (): object => {
  const results = {
    userAgent: navigator.userAgent,
    facebook: isFacebook(),
    performanceTimeline: 'timing' in window.performance,
    DOMHighResTimeStamp: 'now' in window.performance,
    networkInformation: 'connection' in window.navigator,
    navigationEntry: performanceEntryTest('navigation'),
    resourceTimingEntry: performanceEntryTest('resource'),
    webShare: 'share' in window.navigator,
    visibilityChange: 'visibilityState' in document,
    pageHide: 'onpagehide' in window,
    fetch: 'fetch' in window,
    sendBeacon: 'sendBeacon' in window.navigator,
    webSocket: WebSocket != null,
    localStorage: localStorage.length != null,
    performanceObserver: window.PerformanceObserver != null,
    mutationObserver: window.MutationObserver != null,
    intersectionObserver: window.IntersectionObserver != null,
    performancePaintTiming: performanceEntryTest('paint')
  }

  return results
}

const performanceEntryTest = (type: string): boolean => {
  if ('getEntriesByType' in performance) {
    const perfMetrics = window.performance.getEntriesByType(type)
    if (perfMetrics != null && perfMetrics.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const sendAPIResults = (): void => {
  const APIResults = webAPICheck()
  const APITestEndpoint = '/2'

  return request(
    'POST',
    APITestEndpoint,
    serialize(APIResults),
    undefined,
    undefined
  )
}
